import { ITeam, ITeamMembership } from '../../api/services/team.service';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { DateTime } from 'luxon';
import { BiBuilding } from 'react-icons/bi';
import { classNames } from '../../utils';

type TeamOverviewSectionProps = {
  members: ITeamMembership[];
  team: ITeam;
};

function TeamCard({ team }: { team: ITeam }) {
  return (
    <div className='overflow-hidden rounded-lg border border-slate-200 bg-white dark:border-slate-700 dark:bg-slate-900'>
      <div className='flex items-center gap-x-4 border-b border-slate-900/5 bg-slate-50 p-6 dark:bg-slate-800'>
        <img
          src={'https://tailwindui.com/img/logos/48x48/tuple.svg'}
          alt={team.name}
          className='h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-slate-900/10'
        />
        <div className='text-sm font-medium leading-6 text-slate-900 dark:text-white'>
          {team.name}
        </div>
        <MenuComponent team={team} />
      </div>
      <dl className='-my-3 divide-y divide-slate-200 px-6 py-4 text-sm leading-6 dark:divide-slate-400'>
        {/* <div className='flex py-3'>
          <dt className='font-semibold text-slate-500 dark:text-white'>Regulatory</dt>
        </div> */}
        {/* <div className='flex py-3'>
          <dt className='font-semibold text-slate-500 dark:text-white'>Settings</dt>
        </div> */}
        <div className='flex justify-between gap-x-4 py-3'>
          <dt className='text-slate-500 dark:text-white'>Publisher name</dt>
          <dd className='text-slate-700 dark:text-white'>
            {team.publisherName}
          </dd>
        </div>
        {team.teamMembershipDTO?.role !== 'GUEST' && (
          <>
            <div className='flex justify-between gap-x-4 py-3'>
              <dt className='text-slate-500 dark:text-white'>IPI</dt>
              <dd className='text-slate-700 dark:text-white'>{team.ipi}</dd>
            </div>
            <div className='flex justify-between gap-x-4 py-3'>
              <dt className='text-slate-500 dark:text-white'>Country code</dt>
              <dd className='text-slate-700 dark:text-white'>
                {team.countryCode}
              </dd>
            </div>
            <div className='flex justify-between gap-x-4 py-3'>
              <dt className='text-slate-500 dark:text-white'>Label code</dt>
              <dd className='text-slate-700 dark:text-white'>
                {team.labelCode}
              </dd>
            </div>
            <div className='flex justify-between gap-x-4 py-3'>
              <dt className='text-slate-500 dark:text-white'>ISRC Range</dt>
              <dd className='text-slate-700 dark:text-white'>
                {team.isrc_min} - {team.isrc_max}
              </dd>
            </div>
            <div className='flex justify-between gap-x-4 py-3'>
              <dt className='text-slate-500 dark:text-white'>Library code</dt>
              <dd className='text-slate-700 dark:text-white'>
                {team.libraryCode}
              </dd>
            </div>
          </>
        )}

        {/* <div className='flex justify-between gap-x-4 py-3'>
          <dt className='text-slate-500 dark:text-white'>Created</dt>
          <dd className='text-slate-700 dark:text-white'>
            <time>
              {DateTime.fromJSDate(new Date(team.createdAt ?? '')).toRelative()}
            </time>
          </dd>
        </div> */}

        {/* <div className='flex py-3'>
          <dt className='font-semibold text-slate-500 dark:text-white'>Contact</dt>
        </div> */}
        <div className='flex justify-between gap-x-4 py-3'>
          <dt className='text-slate-500 dark:text-white'>Email</dt>
          <dd className='text-slate-700 dark:text-white'>{team.email}</dd>
        </div>
        <div className='flex justify-between gap-x-4 py-3'>
          <dt className='text-slate-500 dark:text-white'>Website</dt>
          <dd className='text-slate-700 dark:text-white'>
            <a
              href={team.website}
              className='font-semibold text-indigo-600 hover:text-indigo-500'
            >
              {team.website}
            </a>
          </dd>
        </div>
      </dl>
    </div>
  );
}

function MenuComponent({ team }: { team: ITeam }) {
  return (
    <Menu as='div' className='relative ml-auto'>
      <MenuButton className='-m-2.5 block p-2.5 text-slate-400 hover:text-slate-500'>
        <span className='sr-only'>Open options</span>
        <EllipsisHorizontalIcon className='h-5 w-5' aria-hidden='true' />
      </MenuButton>
      <MenuItems
        transition
        className='absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-slate-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in dark:bg-slate-600 hover:dark:bg-slate-700'
      >
        {/* <MenuItem>
          {({ active }) => (
            <a
              href='#'
              className={classNames(
                active ? 'bg-slate-50' : '',
                'block px-3 py-1 text-sm leading-6 text-slate-900'
              )}
            >
              View<span className='sr-only'>, {team.name}</span>
            </a>
          )}
        </MenuItem> */}
        <MenuItem>
          {({ active }) => (
            <a
              href='#'
              className={classNames(
                active ? 'bg-slate-50 hover:dark:bg-slate-600' : '',
                'block px-3 py-1 text-sm leading-6 text-slate-900'
              )}
            >
              Edit<span className='sr-only'>, {team.name}</span>
            </a>
          )}
        </MenuItem>
      </MenuItems>
    </Menu>
  );
}

export function TeamOverviewSection({
  members,
  team,
}: TeamOverviewSectionProps) {
  return (
    <div className='flex flex-col space-y-5'>
      <div className='flex items-center space-x-2'>
        <BiBuilding className='text-2xl text-indigo-500' />
        <div className='text-lg font-semibold'>Label info</div>
      </div>

      <div className='flex w-full flex-col overflow-scroll'>
        <TeamCard team={team} />
      </div>
    </div>
  );
}
