import { useAuth } from '../hooks/useAuth';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../api';
import NoTeamSection from '../components/dashboard/NoTeamSection';
import MidnightLogoViolet from '../components/global/midnight_violet.png';
import MidnightLogoYellow from '../components/global/midnight_yellow.png';
import { useGlobalState } from '../state';
import { ITeamInvite, ITeamMembership } from '../api/services/team.service';
import { TeamOverviewSection } from '../components/team/TeamOverviewSection';
import TeamMemberSection from '../components/team/TeamMemberSection';
import { TagSection } from '../components/team/TagSection';

export default function Team() {
  const { user, activeTeam } = useAuth();
  const { theme } = useGlobalState();

  const [members, setMembers] = useState<ITeamMembership[]>([]);
  const [invites, setInvites] = useState<ITeamInvite[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (activeTeam !== null) {
      if (activeTeam.teamMembershipDTO?.role !== 'GUEST') {
        fetchMembers();
        fetchInvites();
      }
    }
  }, [activeTeam]);

  async function fetchInvites() {
    try {
      if (!activeTeam) {
        return;
      }
      setLoading(true);
      const { data } = await api.team.getTeamInvites(activeTeam?.id);
      if (data.result) {
        setInvites(data.result);
      }
      setLoading(false);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  async function fetchMembers() {
    try {
      if (!activeTeam) {
        return;
      }
      setLoading(true);
      const response = await api.team.getTeamMembers(activeTeam.id);
      if (response.data.result) {
        setMembers(response.data.result);
      }
      setLoading(false);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  return (
    <>
      {user && (
        <div className='flex grow flex-col items-center px-4 md:px-8 lg:px-16 xl:px-24'>
          {/* {activeTeam && tenantLandingImageUrl && (
            <img
              className='mt-4 max-h-32'
              src={tenantLandingImageUrl}
              alt='MidnightLogoYellow'
            />
          )} */}
          {/* {activeTeam && !activeTeam.landingImageFileId && ( */}

          {/* )} */}
          {!activeTeam && (
            <div className='mt-16 w-full'>
              <NoTeamSection />
            </div>
          )}
          {activeTeam && (
            <div className=''>
              {theme === 'dark' ? (
                <img
                  className='mt-4 h-16'
                  src={MidnightLogoYellow}
                  alt='MidnightLogoYellow'
                />
              ) : (
                <img
                  className='mt-4 h-16'
                  src={MidnightLogoViolet}
                  alt='MidnightLogoViolet'
                />
              )}
            </div>
          )}
          {activeTeam && user && (
            <div className='mt-16 grid w-full grid-cols-3 gap-6 lg:gap-16 xl:gap-16 2xl:gap-16'>
              <div className='col-span-full space-y-12 lg:col-span-1 '>
                <TeamOverviewSection team={activeTeam} members={members} />
              </div>
              <div className='col-span-full lg:col-span-2'>
                <div className='flex flex-col space-y-12'>
                  {activeTeam.teamMembershipDTO?.role !== 'GUEST' && (
                    <TagSection team={activeTeam} />
                  )}
                  {activeTeam.teamMembershipDTO?.role !== 'GUEST' && (
                    <TeamMemberSection
                      team={activeTeam}
                      members={members}
                      invites={invites}
                      fetchInvites={fetchInvites}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
